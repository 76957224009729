import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { CollectionApi } from '../apis/collection';
import { PAGE_SIZE } from '../config';
import { PaginatedList } from '../libs/paginated-list';
import { CollectedBonus, CollectedRarity } from '../models/collection';
import { LotteryMachine } from '../models/lottery';
import { BonusKind } from '../models/lottery-bonus';
import { LotteryStore } from '../models/lottery-store';
import {
  selectCollectedBonuses,
  selectCollectedBonusesWithKind,
  selectCollectedRarities,
  setCollectedBonuses,
  setCollectedRarities,
} from '../store/collection';
import { selectCollectedMachines, setCollectedMachines } from '../store/lottery-machine';
import { selectCollectedStore, setCollectedStores } from '../store/lottery-store';
import { AppState } from '../store/symbols';

@Injectable({ providedIn: 'root' })
export class CollectionService {
  storesPage: PaginatedList<LotteryStore> = new PaginatedList<LotteryStore>(PAGE_SIZE);
  stores$: Observable<LotteryStore[]>;

  private readonly api = inject(CollectionApi);
  private readonly store = inject<Store<AppState>>(Store);

  constructor() {
    this.stores$ = this.store.select(selectCollectedStore);
  }

  selectMachines(storeSlug: string): Observable<LotteryMachine[]> {
    return this.store.select(selectCollectedMachines(storeSlug));
  }

  selectRarities(lotterySlug: string): Observable<CollectedRarity[]> {
    return this.store.select(selectCollectedRarities(lotterySlug));
  }

  selectBonuses(lotterySlug: string): Observable<CollectedBonus[]> {
    return this.store.select(selectCollectedBonuses(lotterySlug));
  }

  selectBonusesWithKind(lotterySlug: string, kind: BonusKind): Observable<CollectedBonus[]> {
    return this.store.select(selectCollectedBonusesWithKind(lotterySlug, kind));
  }

  fetchStores(init: boolean = false): Observable<LotteryStore[]> {
    const page = init ? 1 : this.storesPage.page + 1;
    return this.api.fetchCollectedStores(page, PAGE_SIZE).pipe(
      tap((stores) => {
        this.store.dispatch(setCollectedStores(stores, init));
        this.storesPage.page = page;
        this.storesPage.setPage(stores);
      }),
    );
  }

  fetchMachines(storeSlug: string): Observable<LotteryMachine[]> {
    return this.api.fetchCollectedMachines(storeSlug).pipe(
      tap((machines) => {
        this.store.dispatch(setCollectedMachines({ storeSlug, targetMachines: machines }));
      }),
    );
  }

  fetchRarities(storeSlug: string, lotterySlug: string): Observable<CollectedRarity[]> {
    return this.api.fetchCollectedRarities(storeSlug, lotterySlug).pipe(
      tap((rarities) => {
        this.store.dispatch(setCollectedRarities({ lotterySlug, rarities }));
      }),
    );
  }

  fetchBonuses(storeSlug: string, lotterySlug: string): Observable<CollectedBonus[]> {
    return this.api.fetchCollectedBonuses(storeSlug, lotterySlug).pipe(
      tap((bonuses) => {
        this.store.dispatch(setCollectedBonuses({ lotterySlug, bonuses }));
      }),
    );
  }

  totalPrizesCount(rarities: CollectedRarity[]): number {
    return rarities.reduce((prev, current) => (prev += current.prizes.length), 0);
  }

  acquiredCount(rarities: CollectedRarity[]): number {
    return rarities.reduce((prev, current) => (prev += current.prizes.filter((prize) => prize.acquired).length), 0);
  }
}
