<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    <sl-breadcrumb>
      <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_top'">{{
        state?.store?.name
      }}</sl-breadcrumb-item>
      <sl-breadcrumb-item>{{ 'shared.breadcrumbs.lotteryList' | locale }}</sl-breadcrumb-item>
    </sl-breadcrumb>
    <sl-page-header>{{ 'shared.breadcrumbs.lotteryList' | locale }}</sl-page-header>
    <sl-layout size="small" class="container" infiniteScroll (scrolled)="onScroll()">
      @if (state?.machines?.length) {
        <div class="lottery-list">
          @for (machine of state?.machines; track machine.id) {
            <sl-lottery-item
              class="lottery-list-item"
              routerLink="{{ machine.id }}"
              [machine]="machine"
            ></sl-lottery-item>
          }
        </div>
      } @else {
        <sl-empty>{{ 'pages.storeLotteryHistory.emptyMessage' | locale }}</sl-empty>
      }
      <div class="back-button">
        <sl-button type="stroked" slBackButton [defaultHref]="state?.store?.id | storeUrl: 'store_top'">{{
          'shared.navigation.back' | locale
        }}</sl-button>
      </div>
    </sl-layout>
  }
</ng-container>
