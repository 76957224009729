import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginatedList } from '@twogate-npm/toolbox-utils';
import { Observable, tap } from 'rxjs';

import { LotteryMachineGroupApi } from '../apis/lottery-machine-group';
import { PAGE_SIZE } from '../config';
import { LotteryMachine } from '../models/lottery';
import { LotteryMachineGroup } from '../models/lottery-machine-group';
import { selectMachineGroupMachines, setMachineGroupMachines } from '../store/lottery-machine';
import {
  selectLotteryMachineGroup,
  selectLotteryMachineGroups,
  setLotteryMachineGroup,
  setLotteryMachineGroups,
} from '../store/lottery-machine-group';
import { AppState } from '../store/symbols';

@Injectable({ providedIn: 'root' })
export class LotteryMachineGroupService {
  machineGroupPages: { [storeSlug: string]: PaginatedList<LotteryMachineGroup> } = {};
  machineGroupMachinePages: { [groupId: string]: PaginatedList<LotteryMachine> } = {};

  private readonly api = inject(LotteryMachineGroupApi);
  private readonly store = inject(Store<AppState>);

  selectMachineGroups(storeSlug: string): Observable<LotteryMachineGroup[]> {
    return this.store.select(selectLotteryMachineGroups(storeSlug));
  }

  selectMachineGroup(groupId: string): Observable<LotteryMachineGroup> {
    return this.store.select(selectLotteryMachineGroup(groupId));
  }

  selectMachineGroupMachines(storeSlug: string, groupId: string): Observable<LotteryMachine[]> {
    return this.store.select(selectMachineGroupMachines(storeSlug, groupId));
  }

  fetchMachineGroups(storeSlug: string, init = false): Observable<LotteryMachineGroup[]> {
    const paginatedList = (this.machineGroupPages[storeSlug] =
      this.machineGroupPages[storeSlug] ?? new PaginatedList<LotteryMachineGroup>(PAGE_SIZE));
    const page = init ? 1 : paginatedList.page + 1;
    return this.api.fetchMachineGroups(storeSlug, page, PAGE_SIZE).pipe(
      tap((machineGroups) => {
        this.store.dispatch(setLotteryMachineGroups(storeSlug, machineGroups, init));
        paginatedList.page = page;
        paginatedList.setPage(machineGroups);
      }),
    );
  }

  fetchMachineGroup(storeSlug: string, groupId: string): Observable<LotteryMachineGroup> {
    return this.api.fetchMachineGroup(storeSlug, groupId).pipe(
      tap((machineGroup) => {
        this.store.dispatch(setLotteryMachineGroup({ targetMachineGroup: machineGroup }));
      }),
    );
  }

  fetchMachineGroupMachines(
    storeSlug: string,
    groupId: string,
    availableOnly: boolean,
    init = false,
  ): Observable<LotteryMachine[]> {
    const paginatedList = (this.machineGroupMachinePages[groupId] =
      this.machineGroupMachinePages[groupId] ?? new PaginatedList<LotteryMachine>(PAGE_SIZE));
    const page = init ? 1 : paginatedList.page + 1;
    return this.api.fetchMachineGroupMachines(storeSlug, groupId, availableOnly, page, PAGE_SIZE).pipe(
      tap((machines) => {
        this.store.dispatch(setMachineGroupMachines(storeSlug, groupId, machines, init));
        paginatedList.page = page;
        paginatedList.setPage(machines);
      }),
    );
  }
}
