import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable, combineLatest, forkJoin, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LotteryItemComponent } from '../../../components/lottery/lottery-item/lottery-item.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryMachine } from '../../../models/lottery';
import { LotteryMachineGroup } from '../../../models/lottery-machine-group';
import { LotteryStore } from '../../../models/lottery-store';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { LocaleService } from '../../../services/locale.service';
import { LotteryMachineGroupService } from '../../../services/lottery-machine-group.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { StoreUrlService } from '../../../services/store-url.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'page-store-section',
  templateUrl: './store-section.page.html',
  styleUrl: './store-section.page.scss',
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LayoutComponent,
    InfiniteScrollDirective,
    MatCheckbox,
    FormsModule,
    LotteryItemComponent,
    RouterLink,
    EmptyComponent,
    ButtonComponent,
    BackButtonDirective,
    AsyncPipe,
    LocalePipe,
    StoreUrlPipe,
  ],
})
export class StoreSectionPage implements OnInit {
  state$: Observable<{
    store: LotteryStore;
    section: LotteryMachineGroup;
    machines: LotteryMachine[];
  }>;
  groupId: string;
  loading = false;
  loadingMore = false;
  loadingMachines = false;
  availableOnly = signal<boolean>(false);

  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly lotteryStoreService = inject(LotteryStoreService);
  private readonly metaTagsService = inject(MetaTagsService);
  private readonly titleService = inject(TitleService);
  private readonly storeUrlService = inject(StoreUrlService);
  private readonly localeService = inject(LocaleService);
  private readonly lotteryMachineGroupService = inject(LotteryMachineGroupService);
  private storeSlug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.storeSlug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.groupId = this.route.snapshot.paramMap.get('sectionSlug');
    this.state$ = combineLatest({
      store: this.lotteryStoreService.fetchStore(this.storeSlug),
      section: this.lotteryMachineGroupService.selectMachineGroup(this.groupId),
      machines: this.lotteryMachineGroupService.selectMachineGroupMachines(this.storeSlug, this.groupId),
    }).pipe(
      tap((state) => {
        this.titleService.setTitle(`${state?.section?.name} - ${state?.store?.name}`);
        this.metaTagsService.updateTags(
          `${state?.section?.name} - ${state?.store?.name} - ${this.localeService.translate('common.appFullName')}`,
          state?.store?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  updateFilter() {
    this.availableOnly.set(!this.availableOnly());
    this.loadingMachines = true;
    this.lotteryMachineGroupService
      .fetchMachineGroupMachines(this.storeSlug, this.groupId, this.availableOnly(), true)
      .subscribe(() => {
        this.loadingMachines = false;
      });
  }

  ngOnInit() {
    this.loading = true;
    forkJoin([
      this.lotteryStoreService.fetchStore(this.storeSlug),
      this.lotteryMachineGroupService.fetchMachineGroup(this.storeSlug, this.groupId),
      this.lotteryMachineGroupService.fetchMachineGroupMachines(
        this.storeSlug,
        this.groupId,
        this.availableOnly(),
        true,
      ),
    ]).subscribe(
      () => {
        this.loading = false;
      },
      () => {
        this.router.navigateByUrl(this.storeUrlService.transform(this.storeSlug, 'store_top'));
      },
    );
  }

  onScroll() {
    if (!this.loadingMore && !this.lotteryMachineGroupService.machineGroupMachinePages[this.groupId]?.isComplete) {
      this.loadingMore = true;
      this.lotteryMachineGroupService
        .fetchMachineGroupMachines(this.storeSlug, this.groupId, this.availableOnly())
        .subscribe(() => {
          this.loadingMore = false;
        });
    }
  }
}
