<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    @if (
      {
        totalStockQuantity: totalStockQuantity(state),
        machineStatus: getMachineStatus(state?.machine),
        minimumPricePlan: minimumPricePlan(state?.machine),
        digitalContent: isDigitalContentAvailable(state?.machine),
      };
      as snapshot
    ) {
      <sl-breadcrumb>
        <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_top'">{{
          state?.store?.name
        }}</sl-breadcrumb-item>
        <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_lottery_list'">{{
          'shared.breadcrumbs.lotteryList' | locale
        }}</sl-breadcrumb-item>
        <sl-breadcrumb-item>{{ state?.machine?.name }}</sl-breadcrumb-item>
      </sl-breadcrumb>

      <sl-layout class="header">
        <div class="header-main" *slRenderOn="['medium', 'large']">
          <img [src]="state?.machine?.mainImage | imageSize: 'large'" alt="" class="header-main-image" />
        </div>
        <div class="header-main is-origin" *slRenderOn="'small'">
          <img
            *slRenderOn="'small'"
            [src]="state?.machine?.squareImage | imageSize: 'medium' : state?.machine?.mainImage"
            alt=""
            class="header-main-image"
          />
        </div>
        @if (state?.machine?.readyForSale && state?.machine?.galleryImages?.length) {
          <sl-image-slider class="header-slider" [images]="state?.machine?.galleryImages"></sl-image-slider>
        }
      </sl-layout>

      <sl-layout size="small" class="content">
        <div class="info">
          <div class="info-name">{{ state?.machine?.name }}</div>
          @switch (snapshot?.machineStatus) {
            @case ('before') {
              <div class="info-status">{{ 'pages.storeLottery.beforeSale' | locale }}</div>
            }
            @case ('not_on_sale') {
              <div class="info-status">{{ 'pages.storeLottery.suspendSale' | locale }}</div>
            }
            @case ('after') {
              <div class="info-status">{{ 'pages.storeLottery.endOfSale' | locale }}</div>
            }
          }
          <div class="info-container">
            <dl class="info-item">
              <dt class="info-item-heading">{{ 'pages.storeLottery.salesPeriodHeading' | locale }}</dt>
              <dd
                class="info-item-body"
                [innerHTML]="
                  'pages.storeLottery.salesPeriod'
                    | locale
                      : {
                          start: state?.machine?.saleStartsAt | localDate: 'long',
                          end: state?.machine?.saleEndsAt | localDate: 'long',
                        }
                    | safeHtml
                "
              ></dd>
            </dl>
            @if (state?.machine?.readyForSale) {
              @if (snapshot?.minimumPricePlan) {
                <dl class="info-item">
                  <dt class="info-item-heading">{{ 'pages.storeLottery.priceHeader' | locale }}</dt>
                  <dd class="info-item-body">
                    {{
                      'pages.storeLottery.minimumPrice'
                        | locale
                          : {
                              numberOfDraws: snapshot?.minimumPricePlan?.numberOfDraws,
                              price:
                                snapshot?.minimumPricePlan?.price
                                | localCurrency
                                  : {
                                      showTaxInfo: true,
                                    },
                            }
                    }}
                  </dd>
                </dl>
              }
              @if ('pages.storeLottery.shippingFeeLabel' | locale) {
                <dl class="info-item">
                  <dt class="info-item-heading">{{ 'pages.storeLottery.shippingFeeLabel' | locale }}</dt>
                  <dd class="info-item-body">{{ 'pages.storeLottery.shippingFeeNote' | locale }}</dd>
                </dl>
              }
              @if ('pages.storeLottery.shippingDateLabel' | locale) {
                <dl class="info-item">
                  <dt class="info-item-heading">{{ 'pages.storeLottery.shippingDateLabel' | locale }}</dt>
                  <dd class="info-item-body">
                    {{ state?.machine?.shippingPeriod?.shippingSchedule }}<br />{{
                      'pages.storeLottery.shippingNote' | locale
                    }}
                  </dd>
                </dl>
              }
              @if (snapshot?.minimumPricePlan) {
                @if ('pages.storeLottery.paymentInfoLabel' | locale) {
                  <dl class="info-item">
                    <dt class="info-item-heading">{{ 'pages.storeLottery.paymentInfoLabel' | locale }}</dt>
                    <dd class="info-item-body">{{ 'pages.storeLottery.paymentInfoNote' | locale }}</dd>
                  </dl>
                }
                @if ('pages.storeLottery.cancelPolicyLabel' | locale) {
                  <dl class="info-item">
                    <dt class="info-item-heading">{{ 'pages.storeLottery.cancelPolicyLabel' | locale }}</dt>
                    <dd
                      class="info-item-body"
                      [innerHTML]="
                        'pages.storeLottery.cancelPolicyNote' | locale: { lawUrl: assets?.lawLink?.href } | safeHtml
                      "
                    ></dd>
                  </dl>
                }
              }
              <div class="info-note">
                <div class="info-note-item">
                  {{ 'pages.storeLottery.noteForPurchasers' | locale: { appFullName: 'common.appFullName' | locale } }}
                </div>
                @if (state?.machine?.modeOfOddsDisclosing === 'initial_stock_proportion') {
                  <div class="info-note-item">{{ 'pages.storeLottery.noteAboutInitialProportion' | locale }}</div>
                  <div class="info-note-item">{{ 'pages.storeLottery.noteAboutTotalProportion' | locale }}</div>
                }
              </div>
              @if (state?.machine?.emailRequired || state?.machine?.phoneNumberRequired) {
                <div class="info-restriction">
                  @if (state?.machine?.emailRequired) {
                    <sl-lottery-restriction-tag type="email"></sl-lottery-restriction-tag>
                  }
                  @if (state?.machine?.phoneNumberRequired) {
                    <sl-lottery-restriction-tag type="phone"></sl-lottery-restriction-tag>
                  }
                </div>
              }
            }
          </div>
        </div>
        <tbx-wysiwyg-body class="content-top" [body]="state?.machine?.body"></tbx-wysiwyg-body>
      </sl-layout>

      <ng-container [ngTemplateOutlet]="PurchaseSection"></ng-container>

      <sl-layout size="small" class="content">
        @if (state?.machine?.readyForSale) {
          <div class="rarities">
            <div class="rarities-heading">
              <div class="en">LINE UP</div>
              @if (state?.machine?.modeOfOddsDisclosing === 'raw') {
                <div class="ja">
                  {{
                    'pages.storeLottery.totalStockQuantity'
                      | locale: { quantity: snapshot?.totalStockQuantity | number }
                  }}
                </div>
              }
              @if (state?.machine?.modeOfOddsDisclosing === 'initial_stock_proportion') {
                <div
                  class="rarities-heading-note"
                  [innerHTML]="
                    'pages.storeLottery.noteForProbabilityNotation' | locale: { bonusName: 'common.bonus' | locale }
                  "
                ></div>
              }
            </div>

            @if (hasBonus(state, 'last_one')) {
              <div class="bonus">
                @for (bonus of getLastOne(state); track bonus.id) {
                  <sl-lottery-bonus
                    class="bonus-item"
                    [bonus]="bonus"
                    [digitalContent]="snapshot.digitalContent"
                  ></sl-lottery-bonus>
                }
              </div>
            }

            @for (rarity of getRarities(state); track rarity.id; let isFirst = $first) {
              <sl-lottery-rarity
                class="rarities-item"
                [rarity]="rarity"
                [totalQuantity]="snapshot?.totalStockQuantity"
                [initialStockProportion]="rarity?.initialStockProportion"
                [modeOfOddsDisclosing]="state?.machine?.modeOfOddsDisclosing"
                [topPrize]="isFirst"
                [digitalContent]="snapshot?.digitalContent"
              ></sl-lottery-rarity>
            }
            <div class="rarities-note">
              <div class="rarities-note-item">
                <sl-icon class="icon" name="check"></sl-icon>
                <div class="text">{{ 'pages.storeLottery.acquiredPrize' | locale }}</div>
              </div>
              <div class="rarities-note-item">
                <sl-icon class="icon small" name="digital-content"></sl-icon>
                <div class="text">
                  {{
                    'pages.storeLottery.withDigitalContent' | locale: { name: 'common.digitalContentsFull' | locale }
                  }}
                </div>
              </div>
            </div>
          </div>
        }

        <tbx-wysiwyg-body class="content-top" [body]="state?.machine?.bottomBody"></tbx-wysiwyg-body>

        @if (hasBonus(state, 'double_chance') && state?.machine?.readyForSale) {
          <div class="bonus">
            @for (bonus of getDoubleChance(state); track bonus.id) {
              <sl-lottery-bonus
                class="bonus-item"
                [bonus]="bonus"
                [digitalContent]="snapshot.digitalContent"
              ></sl-lottery-bonus>
            }
          </div>
        }
      </sl-layout>

      <ng-container [ngTemplateOutlet]="PurchaseSection"></ng-container>

      <sl-layout size="small" class="content page-bottom">
        <div class="how-to">
          <sl-button type="stroked" [routerLink]="state?.store?.id | storeUrl: 'store_top'">{{
            'shared.navigation.backToStoreTop' | locale
          }}</sl-button>
        </div>

        @if (!state?.machine?.limited) {
          <sl-share class="share" text="{{ state?.machine?.name }} - {{ 'common.appName' | locale }}"></sl-share>
        }
      </sl-layout>
      <!-- 購入セクションのテンプレート -->
      <ng-template #PurchaseSection>
        @switch (snapshot?.machineStatus) {
          @case ('on_sale') {
            <ng-container [ngTemplateOutlet]="PricePlans"></ng-container>
          }
          @case ('sold_out') {
            <ng-container [ngTemplateOutlet]="PricePlans"></ng-container>
          }
        }
        <ng-template #PricePlans>
          <div class="purchase" slLayout>
            @if (!state?.purchaseAuthority) {
              <sl-layout size="small" class="purchase-limited">
                <tbx-wysiwyg-body [body]="state?.machine?.purchaseLimitedBody"></tbx-wysiwyg-body>
              </sl-layout>
            }
            <div class="purchase-inner">
              @if (state?.purchaseAuthority) {
                <sl-lottery-plans
                  class="purchase-buttons"
                  [pricePlans]="state?.machine?.pricePlans"
                  [store]="state?.store"
                  [machine]="state?.machine"
                  (draw)="drawLottery(state?.store, state?.machine, $event)"
                ></sl-lottery-plans>
              }
              <div class="purchase-coupon">
                @if (state.coupons?.length) {
                  <div class="purchase-coupon-list">
                    @for (coupon of state.coupons; track coupon.id) {
                      <sl-throttling-button
                        mat-ripple
                        (click)="drawWithCoupon(state.store, state.machine, coupon)"
                        class="purchase-coupon-item"
                      >
                        <div class="purchase-coupon-item-name">{{ coupon.name }}</div>
                        <div class="purchase-coupon-item-detail">
                          <sl-icon class="icon" name="coupon"></sl-icon>
                          <div class="quantity">
                            <div class="value">{{ coupon?.numberOfDraws }}</div>
                            <div class="unit">{{ 'pages.storeLottery.unitName' | locale }}</div>
                          </div>
                        </div>
                      </sl-throttling-button>
                    }
                  </div>
                }
                <div class="purchase-coupon-provider">
                  <a class="purchase-coupon-provider-link" (click)="provideCouponCode(state.store, state.machine)">
                    <sl-icon class="icon" name="coupon"></sl-icon>
                    <div class="text">{{ 'pages.storeLottery.serialCodeNote' | locale }}</div>
                  </a>
                </div>
              </div>
              <div class="purchase-bottom">
                <a matRipple class="purchase-usage" (click)="showUsageModal()">
                  <sl-icon class="icon" name="usage"></sl-icon>
                  <div class="label">{{ 'pages.storeLottery.showHowToPlay' | locale }}</div>
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
    }
  }
</ng-container>
