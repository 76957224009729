import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WysiwygBodyComponent } from '@twogate-npm/toolbox-angular';
import { forkJoin, Observable, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryStore } from '../../../models/lottery-store';
import { Notification } from '../../../models/notification';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { LocaleService } from '../../../services/locale.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { StoreNotificationService } from '../../../services/store-notification.service';
import { TitleService } from '../../../services/title.service';
import { combineStream } from '../../../util';

export type StoreNotificationDetailState = {
  store: LotteryStore;
  notification: Notification;
};

@Component({
  selector: 'page-store-notification-detail',
  templateUrl: './store-notification-detail.page.html',
  styleUrls: ['./store-notification-detail.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LayoutComponent,
    WysiwygBodyComponent,
    ButtonComponent,
    BackButtonDirective,
    AsyncPipe,
    LocalDatePipe,
    LocalePipe,
    StoreUrlPipe,
  ],
})
export class StoreNotificationDetailPage implements OnInit {
  loading = false;
  notificationId: string;
  state$: Observable<StoreNotificationDetailState>;

  private readonly lotteryStoreService = inject(LotteryStoreService);
  private readonly storeNotificationService = inject(StoreNotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly metaTagsService = inject(MetaTagsService);
  private readonly titleService = inject(TitleService);
  private readonly localeService = inject(LocaleService);
  private readonly slug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.slug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.notificationId = this.route.snapshot.paramMap.get('notificationId');
    this.state$ = combineStream({
      store: this.lotteryStoreService.selectStore(this.slug),
      notification: this.storeNotificationService.selectStoreNotification(this.slug, this.notificationId),
    }).pipe(
      tap((state) => {
        this.titleService.setTitle(
          `${state?.notification?.name} - ${this.localeService.translate('shared.breadcrumbs.notification')} - ${
            state?.store?.name
          }`,
        );
        this.metaTagsService.updateTags(
          `${state?.notification?.name} - ${state?.store?.name} - ${this.localeService.translate(
            'common.appFullName',
          )}`,
          state?.store?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  ngOnInit() {
    this.loading = true;
    forkJoin([
      this.lotteryStoreService.fetchStore(this.slug),
      this.storeNotificationService.fetchNotification(this.slug, this.notificationId),
    ]).subscribe(() => {
      this.loading = false;
    });
  }
}
