import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';

import { BannerListComponent } from '../../../components/banner/banner-list/banner-list.component';
import { StoreBanner } from '../../../models/store-banner';
import { STORE_SLUG } from '../../../providers/store-slug';
import { StoreBannerService } from '../../../services/store-banner.service';
import { combineStream } from '../../../util';

export interface StoreContainerState {
  banners: StoreBanner[];
}

@Component({
  selector: 'page-store-container',
  templateUrl: './store-container.page.html',
  styleUrls: ['./store-container.page.scss'],
  standalone: true,
  imports: [RouterOutlet, BannerListComponent, AsyncPipe],
})
export class StoreContainerPage implements OnInit {
  state$: Observable<StoreContainerState>;

  private readonly route = inject(ActivatedRoute);
  private readonly storeBannerService = inject(StoreBannerService);
  private readonly storeSlug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.storeSlug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.state$ = combineStream({ banners: this.storeBannerService.selectBanners(this.storeSlug) });
  }

  ngOnInit(): void {
    this.storeBannerService.fetchStoreBanners(this.storeSlug).subscribe();
  }
}
