import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MetaTagsService {
  private meta = inject(Meta);
  private dom = inject<Document>(DOCUMENT);

  updateTags(title: string, imageUrl: string, url?: string) {
    const tags: MetaDefinition[] = [
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:url',
        content: url ?? window.location.href,
      },
      {
        property: 'og:image',
        content: imageUrl,
      },
    ];
    tags.forEach((tag) => this.meta.updateTag(tag));
  }

  createCanonicalUrl(url?: string) {
    this.refreshCanonicalUrl();

    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url ?? this.dom.URL);
  }

  private refreshCanonicalUrl() {
    const links = this.dom.head.getElementsByTagName('link');
    for (const link of links) {
      if (link.getAttribute('rel') === 'canonical') {
        this.dom.head.removeChild(link);
      }
    }
  }
}
