<ng-container *slLoading="state$ | async as state">
  <sl-breadcrumb>
    <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_top'">{{ state?.store?.name }}</sl-breadcrumb-item>
    <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_notification_list'">{{
      'shared.breadcrumbs.notification' | locale
    }}</sl-breadcrumb-item>
    <sl-breadcrumb-item>{{ state?.notification?.name }}</sl-breadcrumb-item>
  </sl-breadcrumb>

  <sl-layout size="small" class="news">
    <div class="heading">
      <div class="heading-date">{{ state?.notification?.publishedAt | localDate: 'mediumDate' }}</div>
      <div class="heading-title">{{ state?.notification?.name }}</div>
    </div>

    <tbx-wysiwyg-body class="body" [body]="state?.notification?.body"></tbx-wysiwyg-body>

    <div class="back-button">
      <sl-button type="stroked" slBackButton [defaultHref]="state?.store?.id | storeUrl: 'store_notification_list'">{{
        'shared.navigation.back' | locale
      }}</sl-button>
    </div>
  </sl-layout>
</ng-container>
