import { Routes } from '@angular/router';

import { redirectWithQueryParams } from '../../guards/redirect.guard';
import { TabsPageGuard } from '../../guards/tabs-page.guard';
import { EmptyPage } from '../empty/empty.page';

import { MobileCheckOutPage } from './mobile-check-out/mobile-check-out.page';
import { StoreContainerPage } from './store-container/store-container.page';
import { StoreDetailPage } from './store-detail/store-detail.page';
import { StoreLimitedLotteryPage } from './store-limited-lottery/store-limited-lottery.page';
import { StoreLotteryHistoryPage } from './store-lottery-history/store-lottery-history.page';
import { StoreLotteryNotFoundPage } from './store-lottery-not-found/store-lottery-not-found.page';
import { StoreLotteryPage } from './store-lottery/store-lottery.page';
import { StoreNotificationDetailPage } from './store-notification-detail/store-notification-detail.page';
import { StoreNotificationPage } from './store-notification/store-notification.page';
import { StoreRootPage } from './store-root/store-root.page';
import { StoreSectionPage } from './store-section/store-section.page';

const storeRoutes: Routes = [
  {
    path: '',
    canActivate: [TabsPageGuard],
    canDeactivate: [TabsPageGuard],
    children: [
      {
        path: 'top',
        component: EmptyPage,
        canActivate: [redirectWithQueryParams('/store')],
      },
      {
        path: '',
        component: StoreRootPage,
      },
      {
        path: 'mobile_check_out',
        component: MobileCheckOutPage,
      },
      {
        path: ':storeSlug',
        component: StoreContainerPage,
        children: [
          {
            path: '',
            component: StoreDetailPage,
          },
          {
            path: 'category/:sectionSlug',
            component: StoreSectionPage,
          },
          {
            path: 'lottery',
            children: [
              {
                path: '',
                component: StoreLotteryHistoryPage,
              },
              {
                path: ':lotterySlug',
                component: StoreLotteryPage,
              },
            ],
          },
          {
            path: 'limited/:lotterySlug',
            component: StoreLimitedLotteryPage,
          },
          {
            path: 'lottery-not-found',
            component: StoreLotteryNotFoundPage,
          },
          {
            path: 'notification',
            children: [
              {
                path: '',
                component: StoreNotificationPage,
              },
              {
                path: ':notificationId',
                component: StoreNotificationDetailPage,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default storeRoutes;
