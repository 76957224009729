import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { forkJoin, Observable, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LotteryItemComponent } from '../../../components/lottery/lottery-item/lottery-item.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryMachine } from '../../../models/lottery';
import { LotteryStore } from '../../../models/lottery-store';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { LocaleService } from '../../../services/locale.service';
import { LotteryMachineService } from '../../../services/lottery-machine.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { TitleService } from '../../../services/title.service';
import { combineStream } from '../../../util';

export type StoreLotteryHistoryState = {
  store: LotteryStore;
  machines: LotteryMachine[];
};

@Component({
  selector: 'page-store-lottery-history',
  templateUrl: './store-lottery-history.page.html',
  styleUrls: ['./store-lottery-history.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LayoutComponent,
    InfiniteScrollDirective,
    LotteryItemComponent,
    RouterLink,
    EmptyComponent,
    ButtonComponent,
    BackButtonDirective,
    AsyncPipe,
    LocalePipe,
    StoreUrlPipe,
  ],
})
export class StoreLotteryHistoryPage implements OnInit {
  state$: Observable<StoreLotteryHistoryState>;
  loading = true;
  loadingMore = false;

  private route = inject(ActivatedRoute);
  private lotteryStoreService = inject(LotteryStoreService);
  private lotteryMachineService = inject(LotteryMachineService);
  private metaTagsService = inject(MetaTagsService);
  private titleService = inject(TitleService);
  private localeService = inject(LocaleService);
  private storeSlug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.storeSlug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.state$ = combineStream({
      store: this.lotteryStoreService.fetchStore(this.storeSlug),
      machines: this.lotteryMachineService.selectLotteryMachines(this.storeSlug),
    }).pipe(
      tap((state) => {
        this.titleService.setTitle(
          `${this.localeService.translate('shared.breadcrumbs.lotteryList')} - ${state?.store?.name}`,
        );
        this.metaTagsService.updateTags(
          `${this.localeService.translate('shared.breadcrumbs.lotteryList')} - ${
            state?.store?.name
          } - ${this.localeService.translate('common.appFullName')}`,
          state?.store?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  ngOnInit() {
    this.loading = true;
    forkJoin([
      this.lotteryStoreService.fetchStore(this.storeSlug),
      this.lotteryMachineService.fetchLotteryMachines(this.storeSlug, true),
    ]).subscribe(() => {
      this.loading = false;
    });
  }

  onScroll() {
    if (!this.loadingMore && !this.lotteryMachineService.lotteryMachinePages[this.storeSlug]?.isComplete) {
      this.loadingMore = true;
      this.lotteryMachineService.fetchLotteryMachines(this.storeSlug).subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
