import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { LayoutDirective } from '../../../directives/layout.directive';
import { LotteryStore } from '../../../models/lottery-store';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';

@Component({
  selector: 'sl-store-item',
  templateUrl: './store-item.component.html',
  styleUrls: ['./store-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, ImageSizePipe],
})
export class StoreItemComponent implements OnInit {
  @Input() store: LotteryStore;

  constructor() {}

  ngOnInit(): void {}
}
