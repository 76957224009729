<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    <sl-breadcrumb>
      <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_top'">{{
        state?.store?.name
      }}</sl-breadcrumb-item>
      <sl-breadcrumb-item>{{ 'pages.storeLotteryNotFound.notFoundHeading' | locale }}</sl-breadcrumb-item>
    </sl-breadcrumb>
    <sl-layout size="small" class="content">
      <div class="heading">
        <div class="en">NOT FOUND</div>
        <div class="jp">{{ 'pages.storeLotteryNotFound.notFoundHeading' | locale }}</div>
      </div>
      <div class="message" [innerHTML]="'pages.storeLotteryNotFound.notFoundMessage' | locale | safeHtml"></div>
      <div class="button">
        <sl-button type="stroked" [routerLink]="state?.store?.id | storeUrl: 'store_top'">{{
          'shared.navigation.backToStoreTop' | locale
        }}</sl-button>
      </div>
    </sl-layout>
  }
</ng-container>
