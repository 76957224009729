import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ExternalLinkDirective } from '../../../directives/external-link.directive';
import { StoreBanner } from '../../../models/store-banner';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LayoutComponent } from '../../layout/layout.component';

@Component({
  selector: 'sl-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutComponent, ExternalLinkDirective, ImageSizePipe],
})
export class BannerListComponent implements OnInit {
  @Input() banners: StoreBanner[];

  constructor() {}

  ngOnInit(): void {}
}
