import { Component, inject, OnInit } from '@angular/core';
import { User as FirebaseUser } from '@angular/fire/auth';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, map, Observable, take } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { MobilePaymentLotteryCheckOutStatus } from '../../../models/lottery';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { AlertService } from '../../../services/alert.service';
import { LocaleService } from '../../../services/locale.service';
import { LotteryService } from '../../../services/lottery.service';
import { StoreUrlService } from '../../../services/store-url.service';
import { getFirebaseUser } from '../../../store/session';

interface MobilePaymentParameter {
  paymentId: string;
}

@Component({
  selector: 'sl-mobile-check-out',
  templateUrl: './mobile-check-out.page.html',
  styleUrls: ['./mobile-check-out.page.scss'],
  standalone: true,
  imports: [LoadingDirective, ButtonComponent, RouterLink, LocalePipe, StoreUrlPipe],
})
export class MobileCheckOutPage implements OnInit {
  status: MobilePaymentLotteryCheckOutStatus;
  paymentId: string;
  storeSlug: string;
  machineSlug: string;
  isLimited: boolean;
  firebase$: Observable<FirebaseUser>;

  loading = true;

  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly alertService = inject(AlertService);
  private readonly lotteryService = inject(LotteryService);
  private readonly storeUrlService = inject(StoreUrlService);
  private readonly localeService = inject(LocaleService);

  private readonly checkOutStatusKey = 'status';
  private readonly paymentIdKey = 'komoju_payment_id';
  private readonly storeSlugKey = 'channel_slug';
  private readonly machineSlugKey = 'machine_slug';
  private readonly machineLimitedKey = 'machine_limited';

  constructor() {
    this.status = this.route.snapshot.queryParamMap.get(this.checkOutStatusKey) as MobilePaymentLotteryCheckOutStatus;
    this.paymentId = this.route.snapshot.queryParamMap.get(this.paymentIdKey);
    this.storeSlug = this.route.snapshot.queryParamMap.get(this.storeSlugKey);
    this.machineSlug = this.route.snapshot.queryParamMap.get(this.machineSlugKey);
    this.isLimited = this.route.snapshot.queryParamMap.get(this.machineLimitedKey) === 'true';
    this.firebase$ = this.store.select(getFirebaseUser);
  }

  ngOnInit(): void {
    if (this.status === 'captured' || this.status === 'success') {
      this.onSuccess();
    } else if (this.status === 'failure') {
      this.lotteryService.cancelMobilePayment(this.storeSlug, this.machineSlug, this.paymentId).subscribe(
        () => {
          this.loading = false;
          this.cancelLottery();
        },
        () => {
          this.loading = false;
          this.alreadyCanceled();
        },
      );
    } else if (this.status === 'canceled') {
      this.loading = false;
      this.alreadyCanceled();
    } else {
      this.loading = false;
      this.alreadyCanceled();
    }
  }

  async onSuccess() {
    const isAnonymous = await firstValueFrom(
      this.store.select(getFirebaseUser).pipe(
        take(1),
        map((user) => user?.isAnonymous ?? true),
      ),
    );
    if (!isAnonymous) {
      const param: MobilePaymentParameter = {
        paymentId: this.paymentId,
      };
      this.navigateToLotteryPage(this.storeSlug, this.machineSlug, this.isLimited, param);
    } else {
      await this.alertService.showErrorAlert(
        this.localeService.translate('shared.mobilePayment.sessionExpiredOnPurchasingLottery'),
      );
      await firstValueFrom(
        this.lotteryService.cancelMobilePayment(this.storeSlug, this.machineSlug, this.paymentId),
      ).catch(() => null as null);

      this.navigateToLotteryPage(this.storeSlug, this.machineSlug, this.isLimited);
    }
  }

  async cancelLottery() {
    const alert = await this.alertService.showErrorAlert(
      this.localeService.translate('shared.mobilePayment.failedPaymentLotteryAlertMessage'),
    );
    await firstValueFrom(alert.afterClosed());

    this.navigateToLotteryPage(this.storeSlug, this.machineSlug, this.isLimited);
  }

  async alreadyCanceled() {
    const alert = await this.alertService.showErrorAlert(
      this.localeService.translate('shared.mobilePayment.canceledLotteryAlertMessage'),
    );
    await firstValueFrom(alert.afterClosed());

    this.navigateToLotteryPage(this.storeSlug, this.machineSlug, this.isLimited);
  }

  private navigateToLotteryPage(
    storeSlug: string,
    machineSlug: string,
    isLimited: boolean,
    queryParams?: MobilePaymentParameter,
  ) {
    if (storeSlug && machineSlug) {
      if (isLimited) {
        this.router.navigate([this.storeUrlService.transform(storeSlug, 'store_limited_lottery'), machineSlug], {
          queryParams,
          replaceUrl: true,
        });
      } else {
        this.router.navigate([this.storeUrlService.transform(storeSlug, 'store_lottery_list'), machineSlug], {
          queryParams,
          replaceUrl: true,
        });
      }
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }
}
