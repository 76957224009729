<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    <sl-breadcrumb>
      <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_top'">{{
        state?.store?.name
      }}</sl-breadcrumb-item>
      <sl-breadcrumb-item>{{ state.section.name }}</sl-breadcrumb-item>
    </sl-breadcrumb>

    <sl-page-header>{{ state.section.name }}</sl-page-header>

    <sl-layout size="small" class="container" infiniteScroll (scrolled)="onScroll()">
      <div class="filter">
        <mat-checkbox [ngModel]="availableOnly()" (ngModelChange)="updateFilter()" class="filter-item">
          <span class="filter-item-label">{{ 'pages.storeSection.showOnlyAvailable' | locale }}</span>
        </mat-checkbox>
      </div>

      <ng-container *slLoading="!loadingMachines">
        @if (state?.machines?.length) {
          <div class="lottery-list">
            @for (machine of state?.machines; track machine.id) {
              <sl-lottery-item
                class="lottery-list-item"
                [routerLink]="[state?.store?.id | storeUrl: 'store_lottery_list', machine.id]"
                [machine]="machine"
              ></sl-lottery-item>
            }
          </div>
        } @else {
          <sl-empty>{{ 'pages.storeLotteryHistory.emptyMessage' | locale }}</sl-empty>
        }
      </ng-container>

      <div class="back-button">
        <sl-button type="stroked" slBackButton [defaultHref]="state?.store?.id | storeUrl: 'store_top'">{{
          'shared.navigation.back' | locale
        }}</sl-button>
      </div>
    </sl-layout>
  }
</ng-container>
