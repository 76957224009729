import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { LotteryMachine } from '../models/lottery';
import { LotteryMachineGroup } from '../models/lottery-machine-group';

@Injectable({ providedIn: 'root' })
export class LotteryMachineGroupApi {
  private readonly http = inject(HttpClient);

  fetchMachineGroups(storeSlug: string, page: number, perPage: number): Observable<LotteryMachineGroup[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http
      .get<LotteryMachineGroup[]>(`v1/public/channels/${storeSlug}/gacha_machine_groups`, { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.status === 404) {
            return of([]);
          }
          throw error;
        }),
      );
  }

  fetchMachineGroup(storeSlug: string, groupId: string): Observable<LotteryMachineGroup> {
    return this.http.get<LotteryMachineGroup>(`v1/public/channels/${storeSlug}/gacha_machine_groups/${groupId}`);
  }

  fetchMachineGroupMachines(
    storeSlug: string,
    groupId: string,
    availableOnly: boolean,
    page: number,
    perPage: number,
  ): Observable<LotteryMachine[]> {
    let params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    if (availableOnly) {
      params = params.set('filter', 'on_sale_and_in_stock');
    }
    return this.http.get<LotteryMachine[]>(
      `v1/public/channels/${storeSlug}/gacha_machine_groups/${groupId}/gacha_machines`,
      { params },
    );
  }
}
