import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';

import { LotteryMachine, LotteryPricePlan } from '../../../models/lottery';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LocalCurrencyPipe } from '../../../pipes/local-currency.pipe';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { SalesStatusPipe } from '../../../pipes/sales-status.pipe';
import { LotteryMachineService } from '../../../services/lottery-machine.service';

@Component({
  selector: 'sl-lottery-item',
  templateUrl: './lottery-item.component.html',
  styleUrls: ['./lottery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ImageSizePipe, LocalCurrencyPipe, LocalDatePipe, LocalePipe, SalesStatusPipe],
})
export class LotteryItemComponent implements OnInit {
  @Input() machine: LotteryMachine;

  private readonly lotteryMachineService = inject(LotteryMachineService);

  ngOnInit() {}

  lotteryMachineStatus(machine: LotteryMachine) {
    return this.lotteryMachineService.lotteryMachineStatus(machine);
  }

  minimumPricePlan(machine: LotteryMachine): LotteryPricePlan | undefined {
    return this.lotteryMachineService.minimumPricePlan(machine);
  }
}
