import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';

import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { StoreItemComponent } from '../../../components/store/store-item/store-item.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryStore } from '../../../models/lottery-store';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { LotteryStoreService } from '../../../services/lottery-store.service';

@Component({
  selector: 'page-store-root',
  templateUrl: './store-root.page.html',
  styleUrls: ['./store-root.page.scss'],
  standalone: true,
  imports: [
    PageHeaderComponent,
    LoadingDirective,
    LayoutComponent,
    InfiniteScrollDirective,
    StoreItemComponent,
    RouterLink,
    EmptyComponent,
    AsyncPipe,
    LocalePipe,
  ],
})
export class StoreRootPage implements OnInit {
  stores$: Observable<LotteryStore[]>;
  loading = true;
  loadingMore = false;

  private readonly lotteryStoreService = inject(LotteryStoreService);

  constructor() {
    this.stores$ = this.lotteryStoreService.stores$;
  }

  ngOnInit() {
    this.loading = true;
    this.lotteryStoreService.fetchStores(true).subscribe(() => {
      this.loading = false;
    });
  }

  onScroll() {
    if (!this.loadingMore && !this.lotteryStoreService.storePage.isComplete) {
      this.loadingMore = true;
      this.lotteryStoreService.fetchStores().subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
