<ng-container *slLoading="state$ | async as state">
  <sl-breadcrumb>
    <sl-breadcrumb-item [path]="state?.store?.id | storeUrl: 'store_top'">{{ state?.store?.name }}</sl-breadcrumb-item>
    <sl-breadcrumb-item>{{ 'shared.breadcrumbs.notification' | locale }}</sl-breadcrumb-item>
  </sl-breadcrumb>

  <sl-page-header>{{ 'shared.breadcrumbs.notification' | locale }}</sl-page-header>

  <sl-layout size="small" class="body" infiniteScroll (scrolled)="onScroll()">
    <div class="news-list">
      @for (notification of state?.notifications; track notification.id) {
        <sl-notification-item [notification]="notification" [routerLink]="notification?.id"></sl-notification-item>
      }
    </div>

    <div class="back-button">
      <sl-button type="stroked" slBackButton [defaultHref]="state?.store?.id | storeUrl: 'store_top'">{{
        'shared.navigation.back' | locale
      }}</sl-button>
    </div>
  </sl-layout>
</ng-container>
