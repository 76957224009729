import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreNotification } from '../models/store-notification';

@Injectable({ providedIn: 'root' })
export class StoreNotificationApi {
  private readonly http = inject(HttpClient);

  fetchNotifications(storeSlug: string, page: number, perPage: number): Observable<StoreNotification[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<StoreNotification[]>(`v1/public/channels/${storeSlug}/news_items`, { params });
  }

  fetchNotification(storeSlug: string, notificationId: string): Observable<StoreNotification> {
    return this.http.get<StoreNotification>(`v1/public/channels/${storeSlug}/news_items/${notificationId}`);
  }
}
