import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import { Observable, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryStore } from '../../../models/lottery-store';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { LocaleService } from '../../../services/locale.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { TitleService } from '../../../services/title.service';
import { combineStream } from '../../../util';

export interface StoreLotteryNotFoundState {
  store: LotteryStore;
}

@Component({
  selector: 'page-store-lottery-not-found',
  templateUrl: './store-lottery-not-found.page.html',
  styleUrls: ['./store-lottery-not-found.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LayoutComponent,
    ButtonComponent,
    RouterLink,
    AsyncPipe,
    LocalePipe,
    StoreUrlPipe,
    SafeHtmlPipe,
  ],
})
export class StoreLotteryNotFoundPage implements OnInit {
  loading = true;
  state$: Observable<StoreLotteryNotFoundState>;

  private route = inject(ActivatedRoute);
  private lotteryStoreService = inject(LotteryStoreService);
  private metaTagsService = inject(MetaTagsService);
  private titleService = inject(TitleService);
  private localeService = inject(LocaleService);
  private storeSlug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.storeSlug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.state$ = combineStream({ store: this.lotteryStoreService.selectStore(this.storeSlug) }).pipe(
      tap((state) => {
        this.titleService.setTitle(
          `${this.localeService.translate('pages.storeLotteryNotFound.notFoundHeading')} - ${state?.store?.name}`,
        );
        this.metaTagsService.updateTags(
          `${this.localeService.translate('pages.storeLotteryNotFound.notFoundHeading')} - ${
            state?.store?.name
          } - ${this.localeService.translate('common.appFullName')}`,
          state?.store?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  ngOnInit() {
    this.loading = true;
    this.lotteryStoreService.fetchStore(this.storeSlug).subscribe(() => {
      this.loading = false;
    });
  }
}
