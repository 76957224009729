import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { StoreBannerApi } from '../apis/store-banner';
import { StoreBanner } from '../models/store-banner';
import { selectStoreBanners, setStoreBanners } from '../store/store-banner';
import { AppState } from '../store/symbols';

@Injectable({ providedIn: 'root' })
export class StoreBannerService {
  private store = inject<Store<AppState>>(Store);
  private api = inject(StoreBannerApi);

  selectBanners(storeSlug: string): Observable<StoreBanner[]> {
    return this.store.select(selectStoreBanners(storeSlug));
  }

  fetchStoreBanners(storeSlug: string): Observable<StoreBanner[]> {
    return this.api.fetchStoreBanners(storeSlug).pipe(
      tap((banners) => {
        this.store.dispatch(setStoreBanners({ storeSlug, banners }));
      }),
    );
  }
}
