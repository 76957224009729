import { Pipe, PipeTransform, inject } from '@angular/core';

import { LotteryMachineStatus } from '../models/lottery';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'salesStatus',
  standalone: true,
})
export class SalesStatusPipe implements PipeTransform {
  private localeService = inject(LocaleService);

  transform(value: LotteryMachineStatus): string {
    switch (value) {
      case 'on_sale':
        return this.localeService.translate('shared.salesStatus.onSale');
      case 'sold_out':
        return this.localeService.translate('shared.salesStatus.soldOut');
      case 'not_on_sale':
        return this.localeService.translate('shared.salesStatus.notOnSale');
      case 'before':
        return this.localeService.translate('shared.salesStatus.before');
      case 'after':
        return this.localeService.translate('shared.salesStatus.after');
      default:
        return '-';
    }
  }
}
