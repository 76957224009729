import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { StoreNotification } from '../models/store-notification';

export const setStoreNotifications = createAction(
  '[StoreNotification] SetStoreNotifications',
  props<{ storeSlug: string; targetStoreNotifications: StoreNotification[]; init: boolean }>(),
);
export const setStoreNotification = createAction(
  '[StoreNotification] SetStoreNotification',
  props<{ storeSlug: string; targetStoreNotification: StoreNotification }>(),
);

export const adapter: EntityAdapter<StoreNotification> = createEntityAdapter<StoreNotification>();

export interface State {
  storeNotificationsByStore: { [storeSlug: string]: EntityState<StoreNotification> };
}

export const initialState: State = {
  storeNotificationsByStore: {},
};

export const storeNotificationReducer = createReducer(
  initialState,
  on(setStoreNotifications, (state, { storeSlug, targetStoreNotifications, init }) => {
    const storeNotificationsByStore = { ...state.storeNotificationsByStore };
    const currentState = storeNotificationsByStore[storeSlug] ?? adapter.getInitialState();
    storeNotificationsByStore[storeSlug] = init
      ? adapter.setAll(targetStoreNotifications, currentState)
      : adapter.addMany(targetStoreNotifications, currentState);
    return { ...state, storeNotificationsByStore };
  }),
  on(setStoreNotification, (state, { storeSlug, targetStoreNotification }) => {
    const storeNotificationsByStore = { ...state.storeNotificationsByStore };
    const currentState = storeNotificationsByStore[storeSlug] ?? adapter.getInitialState();
    storeNotificationsByStore[storeSlug] = adapter.upsertOne(targetStoreNotification, currentState);
    return { ...state, storeNotificationsByStore };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return storeNotificationReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectFeature = createFeatureSelector<State>('storeNotification');
export const selectStore = (storeSlug: string) =>
  createSelector(selectFeature, (s) => s.storeNotificationsByStore[storeSlug] ?? adapter.getInitialState());
export const selectStoreNotificationEntities = (storeSlug: string) =>
  createSelector(selectStore(storeSlug), selectEntities);
export const selectStoreNotifications = (storeSlug: string) => createSelector(selectStore(storeSlug), selectAll);
export const selectStoreNotification = (storeSlug: string, id: string) =>
  createSelector(selectStoreNotificationEntities(storeSlug), (entities) => entities[id]);
