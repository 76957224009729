import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { StoreBanner } from '../models/store-banner';

export const setStoreBanners = createAction(
  '[StoreBanner] SetStoreBanners',
  props<{ storeSlug: string; banners: StoreBanner[] }>(),
);

export const adapter: EntityAdapter<StoreBanner> = createEntityAdapter<StoreBanner>();

export interface State {
  storeBannersByStore: { [storeSlug: string]: EntityState<StoreBanner> };
}

export const initialState: State = {
  storeBannersByStore: {},
};

export const bannerReducer = createReducer(
  initialState,
  on(setStoreBanners, (state, { storeSlug, banners }) => {
    const storeBannersByStore = { ...state.storeBannersByStore };
    const currentState = storeBannersByStore[storeSlug] ?? adapter.getInitialState();
    storeBannersByStore[storeSlug] = adapter.setAll(banners, currentState);
    return { ...state, storeBannersByStore };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return bannerReducer(state, action);
}

const { selectAll } = adapter.getSelectors();
export const selectFeature = createFeatureSelector<State>('storeBanner');
export const selectStore = (storeSlug: string) =>
  createSelector(selectFeature, (s) => s.storeBannersByStore[storeSlug] ?? adapter.getInitialState());
export const selectStoreBanners = (storeSlug: string) => createSelector(selectStore(storeSlug), selectAll);
