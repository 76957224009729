import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { StoreNotificationApi } from '../apis/store-notification';
import { PAGE_SIZE } from '../config';
import { PaginatedList } from '../libs/paginated-list';
import { StoreNotification } from '../models/store-notification';
import {
  selectStoreNotification,
  selectStoreNotifications,
  setStoreNotification,
  setStoreNotifications,
} from '../store/store-notification';
import { AppState } from '../store/symbols';

@Injectable({ providedIn: 'root' })
export class StoreNotificationService {
  notificationPages: { [storeSlug: string]: PaginatedList<StoreNotification> } = {};

  private readonly api = inject(StoreNotificationApi);
  private readonly store = inject<Store<AppState>>(Store);

  selectNotifications(storeSlug: string): Observable<StoreNotification[]> {
    return this.store.select(selectStoreNotifications(storeSlug));
  }
  selectStoreNotification(storeSlug: string, id: string): Observable<StoreNotification> {
    return this.store.select(selectStoreNotification(storeSlug, id));
  }

  fetchNotifications(storeSlug: string, init: boolean = false): Observable<StoreNotification[]> {
    if (!this.notificationPages[storeSlug]) {
      this.notificationPages[storeSlug] = new PaginatedList<StoreNotification>(PAGE_SIZE);
    }
    const page = init ? 1 : this.notificationPages[storeSlug].page + 1;

    return this.api.fetchNotifications(storeSlug, page, PAGE_SIZE).pipe(
      tap((targetStoreNotifications) => {
        this.store.dispatch(setStoreNotifications({ storeSlug, targetStoreNotifications, init }));
        this.notificationPages[storeSlug].page = page;
        this.notificationPages[storeSlug].setPage(targetStoreNotifications);
      }),
    );
  }

  fetchNotification(storeSlug: string, id: string): Observable<StoreNotification> {
    return this.api.fetchNotification(storeSlug, id).pipe(
      tap((targetStoreNotification) => {
        this.store.dispatch(setStoreNotification({ storeSlug, targetStoreNotification }));
      }),
    );
  }
}
