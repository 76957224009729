@if ({ status: lotteryMachineStatus(machine), minimumPlan: minimumPricePlan(machine) }; as snapshot) {
  <div class="item {{ snapshot?.status }}">
    <div class="status">
      @if (snapshot?.status === 'before') {
        {{ machine.saleStartsAt | localDate: 'mediumDate' }}
      }
      {{ snapshot?.status | salesStatus }}
    </div>
    <div class="thumbnail">
      <img [src]="machine?.thumbnailImage | imageSize: 'medium'" alt="" class="thumbnail-image" />
    </div>
    <div class="meta">
      <div class="name">{{ machine?.name }}</div>
      @if (snapshot?.minimumPlan) {
        <div class="price">
          {{
            'shared.lottery.price'
              | locale
                : {
                    numberOfDraws: snapshot?.minimumPlan?.numberOfDraws,
                    price: snapshot?.minimumPlan?.price | localCurrency: { showTaxInfo: true },
                  }
          }}
        </div>
      }
      @if (machine?.purchaseLimited) {
        <div class="limited">{{ machine?.purchaseLimitedLabel }}</div>
      }
    </div>
  </div>
}
