import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { forkJoin, Observable, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { NotificationItemComponent } from '../../../components/notification/notification-item/notification-item.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryStore } from '../../../models/lottery-store';
import { Notification } from '../../../models/notification';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { LocaleService } from '../../../services/locale.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { StoreNotificationService } from '../../../services/store-notification.service';
import { TitleService } from '../../../services/title.service';
import { combineStream } from '../../../util';

export type StoreNotificationState = {
  store: LotteryStore;
  notifications: Notification[];
};

@Component({
  selector: 'page-store-notification',
  templateUrl: './store-notification.page.html',
  styleUrls: ['./store-notification.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    PageHeaderComponent,
    LayoutComponent,
    InfiniteScrollDirective,
    NotificationItemComponent,
    RouterLink,
    ButtonComponent,
    BackButtonDirective,
    AsyncPipe,
    LocalePipe,
    StoreUrlPipe,
  ],
})
export class StoreNotificationPage implements OnInit {
  loading = false;
  loadingMore = false;
  state$: Observable<StoreNotificationState>;

  private readonly lotteryStoreService = inject(LotteryStoreService);
  private readonly storeNotificationService = inject(StoreNotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly metaTagsService = inject(MetaTagsService);
  private readonly titleService = inject(TitleService);
  private readonly localeService = inject(LocaleService);
  private readonly slug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.slug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.state$ = combineStream({
      store: this.lotteryStoreService.selectStore(this.slug),
      notifications: this.storeNotificationService.selectNotifications(this.slug),
    }).pipe(
      tap((state) => {
        this.titleService.setTitle(
          `${this.localeService.translate('shared.breadcrumbs.notification')} - ${state?.store?.name}`,
        );
        this.metaTagsService.updateTags(
          `${this.localeService.translate('shared.breadcrumbs.notification')} - ${
            state?.store?.name
          } - ${this.localeService.translate('common.appFullName')}`,
          state?.store?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  ngOnInit() {
    this.loading = true;
    forkJoin([
      this.lotteryStoreService.fetchStore(this.slug),
      this.storeNotificationService.fetchNotifications(this.slug, true),
    ]).subscribe(() => {
      this.loading = false;
    });
  }

  onScroll() {
    if (!this.loadingMore && !this.storeNotificationService.notificationPages[this.slug].isComplete) {
      this.loadingMore = true;
      this.storeNotificationService.fetchNotifications(this.slug).subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
