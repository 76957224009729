import { AsyncPipe, DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, HostBinding, inject, InjectFlags, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent, RouterLink } from '@angular/router';
import { SafeHtmlPipe, WysiwygBodyComponent } from '@twogate-npm/toolbox-angular';
import { filter, forkJoin, merge, mergeMap, Observable, of, take, takeUntil, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { ImageSliderComponent } from '../../../components/image/image-slider/image-slider.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LotteryBonusComponent } from '../../../components/lottery/lottery-bonus/lottery-bonus.component';
import { LotteryPlansComponent } from '../../../components/lottery/lottery-plans/lottery-plans.component';
import { LotteryRarityComponent } from '../../../components/lottery/lottery-rarity/lottery-rarity.component';
import { LotteryRestrictionTagComponent } from '../../../components/lottery/lottery-restriction-tag/lottery-restriction-tag.component';
import { ShareComponent } from '../../../components/share/share.component';
import { ThrottlingButtonComponent } from '../../../components/throttling-button/throttling-button.component';
import { LayoutDirective } from '../../../directives/layout.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { RenderOnDirective } from '../../../directives/render-on.directive';
import { SlashkitAssets } from '../../../models/config';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LocalCurrencyPipe } from '../../../pipes/local-currency.pipe';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { StoreUrlPipe } from '../../../pipes/store-url.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { AlertService } from '../../../services/alert.service';
import { SessionService } from '../../../services/auth/session.service';
import { CollectionService } from '../../../services/collection.service';
import { CouponCodeService } from '../../../services/coupon-code.service';
import { LocaleService } from '../../../services/locale.service';
import { LotteryBonusService } from '../../../services/lottery-bonus.service';
import { LotteryCouponService } from '../../../services/lottery-coupon.service';
import { LotteryDrawingService } from '../../../services/lottery-drawing.service';
import { LotteryMachineService } from '../../../services/lottery-machine.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { LotteryService } from '../../../services/lottery.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { ModalService } from '../../../services/modal.service';
import { StoreUrlService } from '../../../services/store-url.service';
import { TitleService } from '../../../services/title.service';
import { combineStream } from '../../../util';
import { LotteryPage, LotteryPageState } from '../store-lottery/lottery-page-abstract';

@Component({
  selector: 'page-store-lottery',
  templateUrl: '../store-lottery/store-lottery.page.html',
  styleUrls: ['../store-lottery/store-lottery.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LayoutComponent,
    RenderOnDirective,
    ImageSliderComponent,
    LotteryRestrictionTagComponent,
    WysiwygBodyComponent,
    NgTemplateOutlet,
    LotteryBonusComponent,
    LotteryRarityComponent,
    IconComponent,
    ButtonComponent,
    RouterLink,
    ShareComponent,
    LayoutDirective,
    LotteryPlansComponent,
    ThrottlingButtonComponent,
    MatRipple,
    AsyncPipe,
    DecimalPipe,
    ImageSizePipe,
    LocalCurrencyPipe,
    LocalDatePipe,
    LocalePipe,
    StoreUrlPipe,
    SafeHtmlPipe,
  ],
})
export class StoreLimitedLotteryPage extends LotteryPage implements OnInit, OnDestroy {
  assets = inject(SlashkitAssets);

  loading = true;
  state$: Observable<LotteryPageState>;
  @HostBinding('attr.data-machine-slug')
  get hostAttrMachineSlug() {
    return this.lotterySlug;
  }
  private lotterySlug: string;
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>();
  private collectionService = inject(CollectionService);
  private lotteryStoreService = inject(LotteryStoreService);
  private lotteryDrawingService = inject(LotteryDrawingService);
  private metaTagsService = inject(MetaTagsService);
  private route = inject(ActivatedRoute);
  private sessionService = inject(SessionService);
  private titleService = inject(TitleService);
  private storeUrlService = inject(StoreUrlService);
  private injector = inject(Injector);
  private storeSlug = inject(STORE_SLUG, { optional: true });

  constructor() {
    const lotteryService = inject(LotteryService);
    const lotteryCouponService = inject(LotteryCouponService);
    const lotteryMachineService = inject(LotteryMachineService);
    const lotteryBonusService = inject(LotteryBonusService);
    const couponCodeService = inject(CouponCodeService);
    const modalService = inject(ModalService);
    const alertService = inject(AlertService);
    const router = inject(Router);
    const localeService = inject(LocaleService);

    super(
      lotteryService,
      lotteryCouponService,
      lotteryMachineService,
      lotteryBonusService,
      couponCodeService,
      alertService,
      modalService,
      router,
      localeService,
    );
    this.initVariables();
  }

  ngOnInit() {
    this.loading = true;
    merge(
      this.lotteryDrawingService.drawEvent,
      this.router.events.pipe(
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
        tap(() => {
          this.initVariables();
          this.loading = true;
        }),
      ),
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.fetchContents();
      });
    this.fetchContents();

    // mobilePayment
    this.lotteryService.completeLottery(this.storeSlug, this.lotterySlug, this.route);
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  initVariables() {
    this.storeSlug =
      this.injector.get(STORE_SLUG, null, InjectFlags.Optional) ?? this.route.snapshot.paramMap.get('storeSlug');
    this.lotterySlug = this.route.snapshot.paramMap.get('lotterySlug');
    this.state$ = combineStream({
      store: this.lotteryStoreService.selectStore(this.storeSlug),
      machine: this.lotteryMachineService.selectLotteryMachine(this.storeSlug, this.lotterySlug),
      collectedRarities: this.collectionService.selectRarities(this.lotterySlug),
      collectedDoubleChance: this.collectionService.selectBonusesWithKind(this.lotterySlug, 'double_chance'),
      collectedLastOne: this.collectionService.selectBonusesWithKind(this.lotterySlug, 'last_one'),
      coupons: this.lotteryCouponService.selectCouponsByLottery(this.lotterySlug),
    }).pipe(
      this.complementPurchaseAuthority(),
      tap((state) => {
        this.titleService.setTitle(`${state?.machine?.name} - ${state?.store?.name}`);
        this.metaTagsService.updateTags(
          `${state?.machine?.name} - ${this.localeService.translate('common.appFullName')}`,
          state?.machine?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  fetchContents() {
    forkJoin([
      this.lotteryStoreService.fetchStore(this.storeSlug),
      this.lotteryMachineService.fetchLimitedLotteryMachine(this.storeSlug, this.lotterySlug),
      this.lotteryCouponService.fetchAllCouponsByLottery(this.lotterySlug),
      this.sessionService.firebaseUser$.pipe(
        take(1),
        mergeMap((firebaseUser) =>
          firebaseUser?.isAnonymous
            ? of([])
            : forkJoin([
                this.collectionService.fetchRarities(this.storeSlug, this.lotterySlug),
                this.collectionService.fetchBonuses(this.storeSlug, this.lotterySlug),
              ]),
        ),
      ),
    ]).subscribe({
      next: ([store, machine, _coupons, [_rarities, _bonuses]]) => {
        if (store && machine) {
          this.loading = false;
        } else {
          this.router.navigateByUrl(this.storeUrlService.transform(this.storeSlug, 'store_lottery-not-found'));
        }
      },
      error: () => {
        this.router.navigateByUrl(this.storeUrlService.transform(this.storeSlug, 'store_lottery-not-found'));
      },
    });
  }
}
