import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { LotteryMachineGroup } from '../models/lottery-machine-group';

export const setLotteryMachineGroups = createAction(
  '[LotteryMachineGroup] SetLotteryMachineGroups',
  (storeSlug: string, targetMachineGroups: LotteryMachineGroup[], init: boolean = false) => ({
    storeSlug,
    targetMachineGroups,
    init,
  }),
);
export const setLotteryMachineGroup = createAction(
  '[LotteryMachineGroup] SetLotteryMachineGroup',
  props<{ targetMachineGroup: LotteryMachineGroup }>(),
);

const adapter = createEntityAdapter<LotteryMachineGroup>();

export interface State extends EntityState<LotteryMachineGroup> {
  groupIdsByStore: { [storeSlug: string]: string[] };
}

export const initialState: State = adapter.getInitialState({ groupIdsByStore: {} });

export const lotteryMachineGroupReducer = createReducer(
  initialState,
  on(setLotteryMachineGroups, (state, { storeSlug, targetMachineGroups, init }) => {
    const groupIdsByStore = { ...state.groupIdsByStore };
    const machineGroupIds = targetMachineGroups.map((machine) => machine.id);
    groupIdsByStore[storeSlug] = init
      ? machineGroupIds
      : [...new Set([...groupIdsByStore[storeSlug], ...machineGroupIds])];
    return adapter.upsertMany(targetMachineGroups, { ...state, groupIdsByStore });
  }),
  on(setLotteryMachineGroup, (state, { targetMachineGroup }) => adapter.upsertOne(targetMachineGroup, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return lotteryMachineGroupReducer(state, action);
}

const { selectEntities } = adapter.getSelectors();
export const selectFeature = createFeatureSelector<State>('lotteryMachineGroup');
export const selectLotteryMachineGroupEntities = createSelector(selectFeature, selectEntities);
export const selectLotteryMachineGroups = (storeSlug: string) =>
  createSelector(selectFeature, selectLotteryMachineGroupEntities, (s, entities) =>
    (s.groupIdsByStore[storeSlug] ?? []).map((id) => entities[id]),
  );
export const selectLotteryMachineGroup = (id: string) =>
  createSelector(selectLotteryMachineGroupEntities, (entities) => entities[id]);
