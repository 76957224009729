<ng-container *slLoading="!loading">
  @if (state$ | async; as state) {
    <sl-layout class="store">
      <div class="store-main">
        <img [src]="state?.store?.mainImage | imageSize: 'large'" alt="" class="store-main-image" />
      </div>
      <div class="store-name">{{ state?.store?.name }}</div>
    </sl-layout>
    <sl-layout size="small" class="content">
      @if (state?.store?.topBody) {
        <tbx-wysiwyg-body class="content-top" [body]="state?.store?.topBody"></tbx-wysiwyg-body>
      }

      @if (state?.notifications?.length) {
        <div class="news">
          <div class="news-list">
            @for (notification of state?.notifications; track notification.id) {
              <sl-notification-item [notification]="notification" routerLink="notification/{{ notification?.id }}">
              </sl-notification-item>
            }
          </div>
          <div class="more">
            <sl-button type="stroked" routerLink="notification">{{
              'pages.storeDetail.showAllNotifications' | locale
            }}</sl-button>
          </div>
        </div>
      }
      @if (state?.store?.body) {
        <tbx-wysiwyg-body class="content-middle" [body]="state?.store.body"></tbx-wysiwyg-body>
      }
      @if (state?.limitedMachines?.length) {
        <div class="lottery">
          <div class="lottery-heading">
            <!-- withAlphabeticCaption -->
            <div class="en">LIMITED</div>
            <div class="ja">{{ 'pages.storeDetail.limitedLottery' | locale }}</div>
          </div>
          <div class="lottery-list">
            @for (machine of state?.limitedMachines; track machine.id) {
              <sl-lottery-item
                class="lottery-list-item"
                routerLink="limited/{{ machine.id }}"
                show-status
                [machine]="machine"
              ></sl-lottery-item>
            }
          </div>
        </div>
      }

      <div class="lottery">
        <div class="lottery-heading">
          <!-- withAlphabeticCaption -->
          <div class="en">LINE UP</div>
          <div class="ja">{{ 'pages.storeDetail.salesLineup' | locale }}</div>
        </div>

        @if (state?.machines?.length) {
          <div class="lottery-list">
            @for (machine of state?.machines; track machine.id) {
              <sl-lottery-item
                class="lottery-list-item"
                routerLink="lottery/{{ machine.id }}"
                [machine]="machine"
              ></sl-lottery-item>
            }
          </div>
        } @else {
          <sl-empty>{{ 'pages.storeDetail.emptySalesLotteryMessage' | locale }}</sl-empty>
        }
        <div class="more">
          <sl-button type="stroked" routerLink="lottery">{{ 'pages.storeDetail.viewAllLottery' | locale }}</sl-button>
        </div>
      </div>

      <div class="group" infiniteScroll (scrolled)="onScroll()">
        @for (section of state.machineGroups; track section.id) {
          @if (section.gachaMachines.length || section.bannerImage) {
            <div class="machine-group">
              <div class="machine-group-heading">{{ section.name }}</div>
              @if (section.bannerImage) {
                <a routerLink="category/{{ section.id }}" class="machine-group-banner"
                  ><img [src]="section.bannerImage | imageSize: 'large'" class="machine-group-banner-image"
                /></a>
              } @else {
                <div class="lottery-list">
                  @for (machine of section.gachaMachines; track machine.id) {
                    <sl-lottery-item
                      class="lottery-list-item"
                      routerLink="lottery/{{ machine.id }}"
                      [machine]="machine"
                    ></sl-lottery-item>
                  }
                </div>
                <div class="more">
                  <sl-button routerLink="category/{{ section.id }}" type="stroked">{{
                    'pages.storeDetail.viewSectionLottery' | locale
                  }}</sl-button>
                </div>
              }
            </div>
          }
        }
      </div>

      @if (state?.store?.bottomBody) {
        <tbx-wysiwyg-body class="content-bottom" [body]="state?.store.bottomBody"></tbx-wysiwyg-body>
      }

      <sl-share
        class="share"
        [text]="
          'pages.storeDetail.shareText' | locale: { storeName: state?.store?.name, appName: 'common.appName' | locale }
        "
      ></sl-share>
    </sl-layout>
  }
</ng-container>
