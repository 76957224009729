import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { WysiwygBodyComponent } from '@twogate-npm/toolbox-angular';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { forkJoin, map, Observable, tap } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { LotteryItemComponent } from '../../../components/lottery/lottery-item/lottery-item.component';
import { NotificationItemComponent } from '../../../components/notification/notification-item/notification-item.component';
import { ShareComponent } from '../../../components/share/share.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { LotteryMachine } from '../../../models/lottery';
import { LotteryMachineGroup } from '../../../models/lottery-machine-group';
import { LotteryStore } from '../../../models/lottery-store';
import { Notification } from '../../../models/notification';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { STORE_SLUG } from '../../../providers/store-slug';
import { LocaleService } from '../../../services/locale.service';
import { LotteryMachineGroupService } from '../../../services/lottery-machine-group.service';
import { LotteryMachineService } from '../../../services/lottery-machine.service';
import { LotteryStoreService } from '../../../services/lottery-store.service';
import { MetaTagsService } from '../../../services/meta-tags.service';
import { StoreNotificationService } from '../../../services/store-notification.service';
import { TitleService } from '../../../services/title.service';
import { combineStream } from '../../../util';

export type StoreDetailState = {
  store: LotteryStore;
  machines: LotteryMachine[];
  limitedMachines: LotteryMachine[];
  machineGroups: LotteryMachineGroup[];
  notifications: Notification[];
};

export const NOTIFICATIONS_PER_PAGE = 3;

@Component({
  selector: 'page-store-detail',
  templateUrl: './store-detail.page.html',
  styleUrls: ['./store-detail.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    LayoutComponent,
    WysiwygBodyComponent,
    NotificationItemComponent,
    RouterLink,
    ButtonComponent,
    LotteryItemComponent,
    EmptyComponent,
    InfiniteScrollDirective,
    ShareComponent,
    AsyncPipe,
    ImageSizePipe,
    LocalePipe,
  ],
})
export class StoreDetailPage implements OnInit {
  loading = false;
  loadingMore = false;
  state$: Observable<StoreDetailState>;

  private readonly lotteryStoreService = inject(LotteryStoreService);
  private readonly lotteryMachineService = inject(LotteryMachineService);
  private readonly storeNotificationService = inject(StoreNotificationService);
  private readonly metaTagsService = inject(MetaTagsService);
  private readonly titleService = inject(TitleService);
  private readonly localeService = inject(LocaleService);
  private readonly lotteryMachineGroupService = inject(LotteryMachineGroupService);
  private readonly route = inject(ActivatedRoute);
  private readonly slug = inject(STORE_SLUG, { optional: true });

  constructor() {
    this.slug ??= this.route.snapshot.paramMap.get('storeSlug');
    this.state$ = combineStream({
      store: this.lotteryStoreService.selectStore(this.slug),
      machines: this.lotteryMachineService
        .selectStorefrontLotteryMachines(this.slug)
        .pipe(
          this.lotteryMachineService.sortMachineByStatus(['on_sale', 'sold_out', 'before', 'not_on_sale', 'after']),
        ),
      limitedMachines: this.lotteryMachineService
        .selectLimitedLotteryMachines(this.slug)
        .pipe(
          this.lotteryMachineService.sortMachineByStatus(['on_sale', 'sold_out', 'before', 'not_on_sale', 'after']),
        ),
      machineGroups: this.lotteryMachineGroupService.selectMachineGroups(this.slug),
      notifications: this.storeNotificationService
        .selectNotifications(this.slug)
        .pipe(map((item) => item.slice(0, NOTIFICATIONS_PER_PAGE))),
    }).pipe(
      tap((state) => {
        this.titleService.setTitle(state?.store?.name);
        this.metaTagsService.updateTags(
          `${state?.store?.name} - ${this.localeService.translate('common.appFullName')}`,
          state?.store?.mainImage?.readingUrls?.medium,
        );
      }),
    );
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([
      this.lotteryStoreService.fetchStore(this.slug),
      this.lotteryMachineService.fetchLotteryMachinesForStorefront(this.slug),
      this.lotteryMachineService.fetchLimitedLotteryMachines(this.slug),
      this.lotteryMachineGroupService.fetchMachineGroups(this.slug, true),
      this.storeNotificationService.fetchNotifications(this.slug, true),
    ]).subscribe(() => {
      this.loading = false;
    });
  }

  onScroll() {
    if (!this.loadingMore && !this.lotteryMachineGroupService.machineGroupPages[this.slug].isComplete) {
      this.loadingMore = true;
      this.lotteryMachineGroupService.fetchMachineGroups(this.slug).subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
