import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { CollectedBonus, CollectedRarity } from '../models/collection';
import { BonusKind } from '../models/lottery-bonus';

export const setCollectedRarities = createAction(
  '[Collection] SetCollectedRarities',
  props<{ lotterySlug: string; rarities: CollectedRarity[] }>(),
);
export const setCollectedBonuses = createAction(
  '[Collection] SetCollectedBonuses',
  props<{ lotterySlug: string; bonuses: CollectedBonus[] }>(),
);

export const rarityAdapter: EntityAdapter<CollectedRarity> = createEntityAdapter<CollectedRarity>();
export const bonusAdapter: EntityAdapter<CollectedBonus> = createEntityAdapter<CollectedBonus>();

export interface State {
  raritiesByLottery: { [lotterySlug: string]: EntityState<CollectedRarity> };
  bonusesByLottery: { [lotterySlug: string]: EntityState<CollectedBonus> };
}

export const initialState: State = {
  raritiesByLottery: {},
  bonusesByLottery: {},
};

export const collectionReducer = createReducer(
  initialState,
  on(setCollectedRarities, (state, { lotterySlug, rarities }) => {
    const raritiesByLottery = { ...state.raritiesByLottery };
    const currentState = raritiesByLottery[lotterySlug] ?? rarityAdapter.getInitialState();
    raritiesByLottery[lotterySlug] = rarityAdapter.setAll(rarities, currentState);
    return { ...state, raritiesByLottery };
  }),
  on(setCollectedBonuses, (state, { lotterySlug, bonuses }) => {
    const bonusesByLottery = { ...state.bonusesByLottery };
    const currentState = bonusesByLottery[lotterySlug] ?? bonusAdapter.getInitialState();
    bonusesByLottery[lotterySlug] = bonusAdapter.setAll(bonuses, currentState);
    return { ...state, bonusesByLottery };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return collectionReducer(state, action);
}

const { selectAll: selectAllRarities } = rarityAdapter.getSelectors();
const { selectAll: selectAllBonuses } = bonusAdapter.getSelectors();

export const selectFeature = createFeatureSelector<State>('collection');
export const selectRarityMachine = (lotterySlug: string) =>
  createSelector(selectFeature, (s) => s.raritiesByLottery[lotterySlug] ?? rarityAdapter.getInitialState());
export const selectCollectedRarities = (lotterySlug: string) =>
  createSelector(selectRarityMachine(lotterySlug), selectAllRarities);
export const selectBonusMachine = (lotterySlug: string) =>
  createSelector(selectFeature, (s) => s.bonusesByLottery[lotterySlug] ?? bonusAdapter.getInitialState());
export const selectCollectedBonuses = (lotterySlug: string) =>
  createSelector(selectBonusMachine(lotterySlug), selectAllBonuses);
export const selectCollectedBonusesWithKind = (lotterySlug: string, kind: BonusKind) =>
  createSelector(selectCollectedBonuses(lotterySlug), (bonuses) => bonuses.filter((bonus) => bonus.kind === kind));
