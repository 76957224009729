<sl-page-header>{{ 'pages.storeRoot.pageHeader' | locale }}</sl-page-header>

<ng-container *slLoading="!loading">
  @if (stores$ | async; as stores) {
    <sl-layout size="small" infiniteScroll (scrolled)="onScroll()">
      @if (stores?.length) {
        <div class="list">
          @for (store of stores; track store.id) {
            <sl-store-item [store]="store" [routerLink]="store?.id"></sl-store-item>
          }
        </div>
      } @else {
        <sl-empty>{{ 'pages.storeRoot.emptyStoreMessage' | locale }}</sl-empty>
      }
    </sl-layout>
  }
</ng-container>
