import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CollectedBonus, CollectedRarity } from '../models/collection';
import { LotteryMachine } from '../models/lottery';
import { LotteryStore } from '../models/lottery-store';

@Injectable({ providedIn: 'root' })
export class CollectionApi {
  private readonly http = inject(HttpClient);

  fetchCollectedStores(page: number, perPage: number): Observable<LotteryStore[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<LotteryStore[]>('v1/private/collection/channels', { params });
  }

  fetchCollectedMachines(storeSlug: string): Observable<LotteryMachine[]> {
    return this.http.get<LotteryMachine[]>(`v1/private/channels/${storeSlug}/gachas/collection/machines`);
  }

  fetchCollectedRarities(storeSlug: string, machineSlug: string): Observable<CollectedRarity[]> {
    return this.http.get<CollectedRarity[]>(
      `v1/private/channels/${storeSlug}/gachas/machines/${machineSlug}/collection/rarities`,
    );
  }

  fetchCollectedBonuses(storeSlug: string, machineSlug: string): Observable<CollectedBonus[]> {
    return this.http.get<CollectedBonus[]>(
      `v1/private/channels/${storeSlug}/gachas/machines/${machineSlug}/collection/bonuses`,
    );
  }
}
